/**
 * App Portal API
 * API to provide applications registered in the App Portal
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The configuration of cycle service for octopus.
 */
export interface App {
  title: string
  description: string
  /**
   * Name of a material design icon. See https://materialdesignicons.com/
   */
  icon: string
  /**
   * Hyperlink to the application
   */
  link: string
  /**
   * Registration ID of the application in the Azure AD.
   */
  registrationId?: string
}

import { HttpHeaders } from '@angular/common/http'
import { Component, Inject } from '@angular/core'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import {
  App,
  AppsService,
  Package,
} from '@dms-frontend/app-portal/services/api'

import { MSAL_INSTANCE } from '@azure/msal-angular'
import { environment } from '../../../environments/environment'
import { appHostedInCloud } from '../../app-routing.module'

export interface AppGroup {
  group: string
  apps: App[]
}

export interface AccessTokenRequest {
  scopes: string[]
  account: AccountInfo
}

@Component({
  selector: 'dms-frontend-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  proPlusSystemInfo: App = {
    title: 'System Info',
    description: 'The system information and usage of resources.',
    icon: 'query_stats',
    link: 'http://' + window.location.hostname + '/ui/systeminformation',
  }
  proPlusAppInfo: App = {
    title: 'App Info',
    description: 'The state of all installed applications and their services.',
    icon: 'apps',
    link: 'http://' + window.location.hostname + '/ui/apps',
  }

  systemMonitoringPackage: Package = {
    name: 'System Monitoring',
    apps: [this.proPlusSystemInfo, this.proPlusAppInfo,],
  }

  packages: Package[] = []
  private accessTokenRequest!: AccessTokenRequest

  constructor(
    private appsService: AppsService,
    @Inject(MSAL_INSTANCE)
    private msalInstance: IPublicClientApplication
  ) {
    if (!appHostedInCloud) {
      this.packages = [this.systemMonitoringPackage]
      return
    }

    appsService.basePath = environment.apiUrl

    // Azure Auth with login redirect
    this.accessTokenRequest = {
      scopes: ['user.read'],
      account: this.msalInstance.getAllAccounts()[0],
    }

    this.isLoggedIn().then((isOnline: boolean) => {
      if (isOnline) {
        this.getApps()
      } else {
        this.msalInstance.loginRedirect()
      }
    })
  }

  private isLoggedIn(): Promise<boolean> {
    return this.msalInstance.acquireTokenSilent(this.accessTokenRequest).then(
      () => {
        return true
      },
      function (error) {
        return false
      }
    )
  }

  private getApps(): void {
    this.msalInstance
      .acquireTokenSilent(this.accessTokenRequest)
      .then((result) => {
        this.appsService.defaultHeaders = new HttpHeaders().set(
          'Token',
          result.accessToken
        )
        this.appsService
          .appsUserUserGet(this.accessTokenRequest.account.localAccountId)
          .subscribe((recvPackage) => {
            this.packages = recvPackage
          })
      })
  }
}

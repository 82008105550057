import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AppTileComponent } from './app-tile/app-tile.component'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [AppTileComponent],
  exports: [AppTileComponent],
})
export class AppPortalUiAppTileModule {}

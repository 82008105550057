<a href="{{ link }}" target="_blank">
  <div
    class="relative hover:bg-secondary-variant bg-secondary w-64 h-44 flex flex-col p-2 border-2 p-2 border-[#eeeeee] rounded-md"
  >
    <div class="flex flex-row justify-center items-start h-[30%] pl-10 pr-10">
      <h6 class="text-center">{{ title }}</h6>
    </div>
    <!-- TODO: text-sm funktioniert nicht-->
    <div class="h-[35%] text-center text-sm">
      {{ description }}
    </div>
    <div class="h-[35%] flex justify-center items-center">
      <mat-icon class="text-[2.5rem] text-medium-emphasis h-auto w-auto">{{
        icon
      }}</mat-icon>
    </div>
  </div>
</a>

import { IconStructure } from './icon-structure.interface'
import { MenuLevel } from './menu-level'

export interface MenuStructure {
  name: string
  menuLevel: MenuLevel
  hasSubMenus: boolean
  subMenuList?: string[]
  iconStructure: IconStructure
}

<dms-frontend-navigation-bar
  class="h-12 inline-block"
  [title]="'Digital Manufacturing Solutions - App Portal'"
  [homeRoute]="''"
  [isMenuOpen]="true"
  [hideIcons]="false"
  [hideSideNavigation]="true"
>
  <img logo class="h-full mr-4 p-1" src="/assets/schaeffler_icon.svg" />
  <div header-menu>
    <div *ngIf="hostedInCloud" class="cursor-pointer" [routerLink]="'/profile'">
      <dms-frontend-menu-header
        [iconStructure]="headerUser"
      ></dms-frontend-menu-header>
    </div>
  </div>
  <div main-content>
    <router-outlet></router-outlet>
  </div>
</dms-frontend-navigation-bar>

import { Component } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { NavigationService } from '@dms-frontend/shared/services/navigation'
import {
  IconStructure,
  MenuIcon,
  MenuLevel,
  MenuStructure,
} from '@dms-frontend/shared/ui/navigation-bar'
import { appHostedInCloud } from './app-routing.module'

@Component({
  selector: 'dms-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  hostedInCloud = appHostedInCloud
  title = 'App Portal'
  home: MenuStructure

  headerUser: IconStructure
  headerInfo: IconStructure

  constructor(
    private router: Router,
    private navigationService: NavigationService
  ) {
    this.home = {
      name: 'Home',
      menuLevel: MenuLevel.Root,
      hasSubMenus: false,
      iconStructure: { icon: 'home', iconStyle: MenuIcon.filled },
    }

    this.headerUser = { icon: 'person', iconStyle: MenuIcon.filled }
    this.headerInfo = { icon: 'info', iconStyle: MenuIcon.filled }

    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        switch (e.url) {
        case '/':
          this.navigationService.setFocusSignal(this.home.name)
          break
          // Case '/dashboard/overview':
          // this.navigationService.setFocusSignal(this.overview.name)
          // break
        default:
          break
        }
      }
    })
  }
}

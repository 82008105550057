/**
 * App Portal API
 * API to provide applications registered in the App Portal
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'

import { Observable } from 'rxjs'

import { Configuration } from '../configuration'
import { App, Package } from '../model/models'
import { BASE_PATH } from '../variables'

@Injectable()
export class AppsService {
  basePath = 'http://localhost:8090'
  defaultHeaders = new HttpHeaders()
  configuration = new Configuration()

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath
    }
    if (configuration) {
      this.configuration = configuration
      this.basePath = basePath || configuration.basePath || this.basePath
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data'
    for (const consume of consumes) {
      if (form === consume) {
        return true
      }
    }
    return false
  }

  /**
   * Get the registered apps of a user
   *
   * @param user The ID of the user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  appsUserUserGet(
    user: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Package>>
  appsUserUserGet(
    user: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Package>>>
  appsUserUserGet(
    user: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Package>>>
  appsUserUserGet(
    user: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (user == null) {
      throw new Error(
        'Required parameter user was null or undefined when calling appsUserUserGet.'
      )
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<Array<App>>(
      'get',
      `${this.basePath}/apps/user/${encodeURIComponent(String(user))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }
}

import { HttpClientModule } from '@angular/common/http'

import { MsalRedirectComponent } from '@azure/msal-angular'

import {
  AzureConfig,
  MsalGuardConfig,
  MsalInstanceConfig,
  MsalInterceptorConfig,
  ProtectedResource,
  SharedAzureAuthModule,
} from '@dms-frontend/shared/services/azure-auth'

import { environment } from '../environments/environment'
import { NgModule } from '@angular/core'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppPortalServicesApiModule } from '@dms-frontend/app-portal/services/api'
import { SharedUiNavigationBarModule } from '@dms-frontend/shared/ui/navigation-bar'
import { AppPortalUiAppTileModule } from '@dms-frontend/app-portal/ui/app-tile'
import { HomeComponent } from './pages/home/home.component'

const azureConfig = new AzureConfig(
  new MsalInstanceConfig(
    environment.clientId,
    environment.tenantId,
    !environment.production
  ),
  new MsalInterceptorConfig([
    new ProtectedResource('/api/*', [environment.appScope]), // The resources the scope is needed
  ]),
  new MsalGuardConfig('/login-failed', [
    // Redirect URI for failed login requests
    environment.appScope,
  ])
)

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppPortalServicesApiModule,
    AppRoutingModule,
    HttpClientModule,
    SharedUiNavigationBarModule,
    AppPortalUiAppTileModule,
    SharedAzureAuthModule.forRoot(azureConfig),
  ],
  providers: [],
  bootstrap: [AppComponent, MsalRedirectComponent], // MsalRedirectComponent handles redirects of your OAuth 2.0 process
})
export class AppModule {}

import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MsalGuard } from '@azure/msal-angular'
import { ProfileComponent } from '@dms-frontend/shared/ui/profile'
import { HomeComponent } from './pages/home/home.component'

const hostname = window.location.hostname
console.log(hostname)
export const appHostedInCloud =
  hostname.includes('schaeffler') || hostname.includes('azure')

let routes: Routes = []
if (appHostedInCloud) {
  routes = [
    {
      // Needed for hash routing
      path: 'code',
      component: HomeComponent,
    },

    {
      // Needed for Error routing
      path: 'error',
      component: HomeComponent,
    },
    {
      path: '',
      component: HomeComponent,
      canActivate: [MsalGuard],
    },
    {
      path: 'profile',
      component: ProfileComponent,
      canActivate: [MsalGuard],
    },
  ]
} else {
  routes = [
    {
      // Needed for hash routing
      path: 'code',
      component: HomeComponent,
    },

    {
      // Needed for Error routing
      path: 'error',
      component: HomeComponent,
    },
    {
      path: '',
      component: HomeComponent,
    },
  ]
}
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule {}

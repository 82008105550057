import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonModule } from '@angular/common'

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me'
// https://learn.microsoft.com/de-de/azure/active-directory/develop/tutorial-v2-angular-auth-code
interface ProfileType {
  givenName?: string
  surname?: string
  userPrincipalName?: string
  id?: string
}

@Component({
  selector: 'dms-frontend-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profile?: ProfileType

  constructor(private http: HttpClient) {
    this.getProfile()
  }

  ngOnInit(): void {
    this.getProfile()
  }

  getProfile(): void {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile
    })
  }
}

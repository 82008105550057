import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MenuHeaderComponent } from './menu-header/menu-header.component'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MenuPointComponent } from './menu-point/menu-point.component'
import { MatSidenavModule } from '@angular/material/sidenav'
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component'
import { SCREEN_SIZE_TRESH_SMALL } from '@dms-frontend/shared/injection-tokens'

@NgModule({
  imports: [
    CommonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
  ],
  declarations: [
    MenuHeaderComponent,
    MenuPointComponent,
    NavigationBarComponent,
  ],
  exports: [MenuHeaderComponent, MenuPointComponent, NavigationBarComponent],
  providers: [
    {
      provide: SCREEN_SIZE_TRESH_SMALL,
      useValue: 905,
    },
  ],
})
export class SharedUiNavigationBarModule {}

import { Component, Input } from '@angular/core'

@Component({
  selector: 'dms-frontend-app-tile',
  templateUrl: './app-tile.component.html',
  styleUrls: ['./app-tile.component.scss'],
})
export class AppTileComponent {
  @Input() title!: string
  @Input() description!: string
  @Input() link!: string
  @Input() icon!: string
}

<div class="container ml-4">
  <div
    *ngFor="let package of packages; let i = index"
    [style.margin-top]="i === 0 ? '1rem' : '2rem'"
  >
    <h5>
      {{ package.name }}
    </h5>
    <span
      *ngFor="let app of package.apps; let i = index"
      class="inline-block mt-2 mb-2 mr-4"
    >
      <dms-frontend-app-tile
        [title]="app.title"
        [description]="app.description"
        [link]="app.link"
        [icon]="app.icon"
      ></dms-frontend-app-tile>
    </span>
  </div>
</div>

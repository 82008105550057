// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  tenantId: '67416604-6509-4014-9859-45e709f53d3f', // Tenant id of your Azure App Registration
  clientId: 'ee2b8db3-d52f-49cd-b7e3-1b9ca8601937', // Client id of your Azure App Registration
  appScope: 'https://worksite.onmicrosoft.com/SG_AppPortal_D/user_impersonation', // Scope that is desired in your claim
  apiUrl: 'https://connectivity-prod.germanywestcentral.cloudapp.azure.com', // URL of the API backend service
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// Import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

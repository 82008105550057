<div id="navigation-container" class="flex flex-col h-screen">
  <nav
    class="flex w-full items-center justify-between header-height px-5 bg-white text-medium-emphasis border-b-2 border-border z-10 md:w-screen"
  >
    <div class="flex items-center h-full">
      <button
        *ngIf="!hideSideNavigation"
        type="button"
        class="mr-2"
        (click)="toggle()"
      >
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          class="w-6 h-6"
        >
          <path d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
      <!-- Logo -->
      <div class="h-full">
        <ng-content [select]="logo"></ng-content>
      </div>
      <div
        class="hidden md:flex text-h6 font-[Roboto] font-medium w-full py-4 hover:cursor-pointer"
        [class.ml-4]="!hideSideNavigation"
        (click)="navigateHome()"
      >
        {{ title }}
      </div>
    </div>
    <div class="flex items-center md:hidden">
      <!-- Schaeffler Logo -->
      <svg
        class="h-auto w-48"
        viewBox="0 0 614.5 68"
        enable-background="new 0 0 614.5 68"
        xml:space="preserve"
      >
        <g id="layer1" transform="translate(-292.753, -526.917)">
          <path
            id="schaeffler-logo"
            fill="#00893D"
            d="
              M312.984,527.018c-15.205,0-20.231,11.875-20.231,17.897
              c0,16.823,14.808,18.667,17.508,19.454c10.777,3.252,17.508,3.035,17.508,11.867c0,5.385-4.513,9.531-9.727,9.531
              c-8.809,0-14.489-6.015-15.563-14.59h-9.143v22.178h9.338v-4.086c4.661,3.883,10.326,5.642,16.341,5.642
              c11.867,0,20.426-8.085,20.426-20.231c0-14.03-8.505-17.236-17.313-19.843c-8.816-2.591-17.703-3.898-17.703-10.116
              c0-5.564,3.852-8.754,9.144-8.754c3.603,0,6.497,1.361,8.754,3.501c2.155,2.07,3.758,4.941,4.474,8.365h8.949v-19.453h-9.532v4.085
              C322.696,529.126,318.019,527.018,312.984,527.018z M377.57,527.018c-15.555,0-32.876,10.614-32.876,33.46
              c0,19.406,14.372,34.433,33.071,34.433c14.123,0,25.398-7.61,29.18-23.149h-10.699c-3.603,7.182-8.762,12.256-18.481,12.256
              c-13.757,0-21.399-10.723-21.399-23.15c0-12.497,7.828-23.15,21.399-23.15c3.688,0,7.455,1.253,10.505,3.307
              c3.058,2,5.525,4.7,6.419,8.365h9.338v-21.01h-9.338v4.279C389.468,527.726,379.632,527.018,377.57,527.018z M413.559,528.379
              v8.948h7.003V584.6h-7.003v8.755h25.679V584.6h-7.003v-20.62h23.15v20.62h-6.809v8.755h25.484V584.6h-7.003v-47.272h7.003v-8.948
              h-25.484v8.948h6.809v16.341h-23.15v-16.341h7.003v-8.948H413.559z M495.847,528.379v8.948h7.198L486.898,584.6h-5.447v8.755
              h23.344V584.6h-7.003l4.475-12.645h19.648l4.474,12.645h-7.198v8.755h23.928V584.6h-4.863l-15.757-47.272h7.003v-8.948H495.847z
              M552.651,528.379v8.948h7.003V584.6h-7.003v8.755h49.995V573.9h-10.31V584.6h-21.01v-20.62h18.092v-10.311h-18.092v-16.341h21.01
              v8.365h10.31v-17.313H552.651z M614.708,528.379v8.948h7.003V584.6h-7.003v8.755h26.263V584.6h-7.587v-20.62h19.453v-10.311
              h-19.453v-16.341h21.787v8.365h10.311v-17.313H614.708z M673.263,528.379v8.948h7.004V584.6h-7.004v8.755h26.263V584.6h-7.587
              v-20.62h19.453v-10.311h-19.453v-16.341h21.788v8.365h10.31v-17.313H673.263z M734.152,528.379v8.948h7.393V584.6h-7.393v8.755
              h44.743v-19.26h-10.311V584.6h-15.368v-47.272h7.976v-8.948H734.152z M785.51,528.379v8.948h6.809V584.6h-6.809v8.755h49.995V573.9
              h-10.311V584.6H803.99v-20.62h18.092v-10.311H803.99v-16.341h21.204v8.365h10.311v-17.313H785.51z M845.815,528.379v8.948h7.003
              V584.6h-7.003v8.755h25.873V584.6h-7.197v-20.62h7.003c4.777,0,11.283,0.514,11.283,6.613v22.762h18.092V584.6h-6.42v-14.784
              c0-3.953-0.607-8.521-7.976-10.311v-0.195c6.824-2.871,10.115-7.034,10.115-14.59c0-0.988,0.249-5.183-2.724-8.949
              c-6.101-8.116-15.609-7.392-20.037-7.392H845.815z M864.491,537.328h6.225c6.101,0,14.396-0.016,14.396,7.976
              c0,8.739-6.567,9.727-13.034,9.727h-7.586V537.328z M512.188,541.218h0.195l7.198,21.789h-14.785L512.188,541.218z
              "
          />
        </g>
      </svg>
    </div>
    <div class="flex items-center">
      <ng-content select="[header-menu]"></ng-content>
      <div class="hidden md:flex">
        <!-- Schaeffler Logo -->
        <svg
          class="h-auto w-48"
          viewBox="0 0 614.5 68"
          enable-background="new 0 0 614.5 68"
          xml:space="preserve"
        >
          <g id="layer1" transform="translate(-292.753, -526.917)">
            <path
              id="schaeffler-logo"
              fill="#00893D"
              d="
                M312.984,527.018c-15.205,0-20.231,11.875-20.231,17.897
                c0,16.823,14.808,18.667,17.508,19.454c10.777,3.252,17.508,3.035,17.508,11.867c0,5.385-4.513,9.531-9.727,9.531
                c-8.809,0-14.489-6.015-15.563-14.59h-9.143v22.178h9.338v-4.086c4.661,3.883,10.326,5.642,16.341,5.642
                c11.867,0,20.426-8.085,20.426-20.231c0-14.03-8.505-17.236-17.313-19.843c-8.816-2.591-17.703-3.898-17.703-10.116
                c0-5.564,3.852-8.754,9.144-8.754c3.603,0,6.497,1.361,8.754,3.501c2.155,2.07,3.758,4.941,4.474,8.365h8.949v-19.453h-9.532v4.085
                C322.696,529.126,318.019,527.018,312.984,527.018z M377.57,527.018c-15.555,0-32.876,10.614-32.876,33.46
                c0,19.406,14.372,34.433,33.071,34.433c14.123,0,25.398-7.61,29.18-23.149h-10.699c-3.603,7.182-8.762,12.256-18.481,12.256
                c-13.757,0-21.399-10.723-21.399-23.15c0-12.497,7.828-23.15,21.399-23.15c3.688,0,7.455,1.253,10.505,3.307
                c3.058,2,5.525,4.7,6.419,8.365h9.338v-21.01h-9.338v4.279C389.468,527.726,379.632,527.018,377.57,527.018z M413.559,528.379
                v8.948h7.003V584.6h-7.003v8.755h25.679V584.6h-7.003v-20.62h23.15v20.62h-6.809v8.755h25.484V584.6h-7.003v-47.272h7.003v-8.948
                h-25.484v8.948h6.809v16.341h-23.15v-16.341h7.003v-8.948H413.559z M495.847,528.379v8.948h7.198L486.898,584.6h-5.447v8.755
                h23.344V584.6h-7.003l4.475-12.645h19.648l4.474,12.645h-7.198v8.755h23.928V584.6h-4.863l-15.757-47.272h7.003v-8.948H495.847z
                M552.651,528.379v8.948h7.003V584.6h-7.003v8.755h49.995V573.9h-10.31V584.6h-21.01v-20.62h18.092v-10.311h-18.092v-16.341h21.01
                v8.365h10.31v-17.313H552.651z M614.708,528.379v8.948h7.003V584.6h-7.003v8.755h26.263V584.6h-7.587v-20.62h19.453v-10.311
                h-19.453v-16.341h21.787v8.365h10.311v-17.313H614.708z M673.263,528.379v8.948h7.004V584.6h-7.004v8.755h26.263V584.6h-7.587
                v-20.62h19.453v-10.311h-19.453v-16.341h21.788v8.365h10.31v-17.313H673.263z M734.152,528.379v8.948h7.393V584.6h-7.393v8.755
                h44.743v-19.26h-10.311V584.6h-15.368v-47.272h7.976v-8.948H734.152z M785.51,528.379v8.948h6.809V584.6h-6.809v8.755h49.995V573.9
                h-10.311V584.6H803.99v-20.62h18.092v-10.311H803.99v-16.341h21.204v8.365h10.311v-17.313H785.51z M845.815,528.379v8.948h7.003
                V584.6h-7.003v8.755h25.873V584.6h-7.197v-20.62h7.003c4.777,0,11.283,0.514,11.283,6.613v22.762h18.092V584.6h-6.42v-14.784
                c0-3.953-0.607-8.521-7.976-10.311v-0.195c6.824-2.871,10.115-7.034,10.115-14.59c0-0.988,0.249-5.183-2.724-8.949
                c-6.101-8.116-15.609-7.392-20.037-7.392H845.815z M864.491,537.328h6.225c6.101,0,14.396-0.016,14.396,7.976
                c0,8.739-6.567,9.727-13.034,9.727h-7.586V537.328z M512.188,541.218h0.195l7.198,21.789h-14.785L512.188,541.218z
                "
            />
          </g>
        </svg>
      </div>
    </div>
  </nav>
  <mat-sidenav-container
    class="flex z-10 h-full w-screen app-background-color"
    autosize
  >
    <mat-sidenav
      *ngIf="!hideSideNavigation"
      #sidenav
      class="h-full"
      mode="side"
      [class.w-full]="isMenuOpen"
      [class.w-0]="!isMenuOpen"
      [class.sm:w-60]="isMenuOpen"
      [class.sm:w-0]="!isMenuOpen"
      [class.md:w-60]="isMenuOpen"
      [class.md:w-16]="!isMenuOpen && !hideIcons"
      opened
    >
      <div
        class="text-h6 font-[Roboto] font-medium w-48 px-6 py-4 md:hidden hover:cursor-pointer"
        (click)="navigateHome()"
      >
        {{ title }}
      </div>
      <div class="border-b-2 border-border md:hidden"></div>
      <div class="lg:overflow-y-hidden">
        <ng-content select="[menu-points]"></ng-content>
      </div>
    </mat-sidenav>

    <!-- content of site: > medium sized: margin-left = 4 + mat-sidenav-width -->
    <mat-sidenav-content
      #sidenavcontent
      class="h-full w-full"
      [class.md:ml-2]="!isMenuOpen && hideIcons && !hideSideNavigation"
      [class.md:ml-20]="!isMenuOpen && !hideIcons && !hideSideNavigation"
      [class.md:ml-64]="isMenuOpen && !hideSideNavigation"
    >
      <div class="w-full h-full">
        <ng-content select="[main-content]"></ng-content>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { NavigationService } from '@dms-frontend/shared/services/navigation'

@UntilDestroy()
@Component({
  selector: 'dms-frontend-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent {
  @ViewChild('sidenavcontent') sideNavContent!: ElementRef

  @Input() title!: string
  @Input() homeRoute!: string
  @Input() isMenuOpen?: boolean
  @Input() hideIcons?: boolean
  @Input() hideSideNavigation = false

  constructor(
    private _router: Router,
    private _navigationService: NavigationService
  ) {
    this._navigationService.isMenuOpen$
      .pipe(untilDestroyed(this))
      .subscribe((menuState: boolean) => {
        this.isMenuOpen = menuState
      })
  }

  toggle(): void {
    this._navigationService.setMenuState(!this.isMenuOpen)
  }

  navigateHome(): void {
    this._router.navigate([this.homeRoute])
  }
}

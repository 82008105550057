<table class="ml-4 mt-4">
  <tr>
    <td><strong>First Name</strong></td>
    <td class="pl-2">{{ profile?.givenName }}</td>
  </tr>
  <tr></tr>
  <tr>
    <td><strong>Last Name</strong></td>
    <td class="pl-2">{{ profile?.surname }}</td>
  </tr>
  <tr></tr>
  <tr>
    <td><strong>Email</strong></td>
    <td class="pl-2">{{ profile?.userPrincipalName }}</td>
  </tr>
  <tr>
    <td><strong>Id</strong></td>
    <td class="pl-2">{{ profile?.id }}</td>
  </tr>
</table>
